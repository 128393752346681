<template>
  <div class="relative">
    <div
      class="
        flex
        absolute
        inset-y-0
        left-0
        items-center
        pl-3
        pointer-events-none
      "
    >
      <span class="material-symbols-outlined text-white opacity-50 text-md">
        person
      </span>
    </div>
    <input
      :type="type"
      :id="type"
      @keyup="onInput"
      class="
        h-10
        block
        p-4
        pl-10
        w-full
        text-sm text-white opacity-50
        bg-transparent
        rounded
        border
        focus:border-lime-500 focus:ring-1 focus:ring-lime-500
      "
      :placeholder="placeholder"
      required
    />
  </div>
</template>

<script>
import { toRefs } from "vue";
export default {
  props:['type', 'placeholder', 'icon', 'value'],
  emits: ['onInput'],
  setup(props, { emit }) {
    const { type, placeholder, icon } = toRefs(props);
    const onInput = (event) => {
      emit('onInput', event.target.value);
    }
    return { type, placeholder, icon, onInput}
  },
};
</script>

<style>
</style>