<template>
  <div>
    <div
      class="hover:opacity-30 cursor-pointer hidden md:flex md:flex-col md:w-36 md:h-32 lg:flex lg:flex-col lg:w-44 lg:h-40 border rounded-md border-white justify-center items-center border-opacity-50"
    >
      <span
        class="mb-1 material-symbols-outlined text-md md:text-5xl lg:text-5xl font-semibold"
      >
        {{ icon }}
      </span>
      <span class="mb-1 font-extrabold text-sm md:text-2xl lg:text-2xl">{{
        value
      }}</span>
      <h1 class="mb-1 font-extrabold text-xs md:text-lg lg:text-lg">
        {{ label }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadIcon",
  props: {
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>