import router from "@/router";
import store from "@/store";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API, //url base da sua api
});
api.interceptors.request.use(async (config) => {
  const token = await store.getters.accessToken;
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";
  return config;
});
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401].includes(error.response?.status)) {
      store.dispatch("logout");
      router.push("/login");
    }
  }
);
export default api;
