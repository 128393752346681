<script setup>
import useStorage from "@/helpers/cloudStorage";
import { getDownloadURL } from "firebase/storage";
const { onMounted, ref: vueRef } = require("vue");
const { useRouter } = require("vue-router");
const { firebaseStorage, ref, uploadBytes } = useStorage();
const emit = defineEmits(["disableButton", "setUrl"]);
const fileUrl = vueRef("");
const spin = vueRef(false);
const handleUploadFile = async (event) => {
  emit("disableButton", true);
  spin.value = true;
  const files = event.target.files;
  const testRef = ref(firebaseStorage, `images/${files[0].name}`);
  uploadBytes(testRef, files[0]).then((snapshot) => {
    // let url = snapshot.g
    getDownloadURL(snapshot.ref)
      .then((url) => {
        fileUrl.value = url;
        spin.value = false;
        emit("disableButton", false);
        emit("setUrl", fileUrl.value);
      })
      .catch((e) => {
        console.log(e);
      });
  });
};
</script>
<template>
  <div class="flex items-center">
    <span v-if="spin" class="flex items-center">
      <span class="animate-spin material-symbols-outlined"> rotate_right </span>
      Uploading....
    </span>
    <input
      @change.prevent="handleUploadFile($event)"
      type="file"
      id="avatar"
      name="avatar"
      accept="image/png, image/jpeg, "
    />
  </div>
</template>