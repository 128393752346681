<template>
  <div class="
      bg-background-login
      bg-cover
      bg-right-top
      bg-no-repeat
      md:bg-center
      flex flex-col
      w-screen
      h-screen
    ">
    <div class="flex flex-col md:justify-center items-center h-screen w-screen justify-end">
      <div class="md:w-64 mb-8 w-52">
        <img src="../assets/logo.svg" alt="" />
      </div>
      <div class="flex flex-col items-center">
        <div class="box-login rounded-t-3xl flex flex-col justify-center gap-8 p-6 md:p-10 md:rounded-3xl">
          <div class="flex flex-col gap-3">
            <span class="text-center text-white md:text-4xl leading-tight text-4xl">
              Seja Bem-vindo a nossa plataforma
            </span>
            <span class="text-center text-white opacity-50">Faça seu login para gerir as publicidades</span>
          </div>

          <div class="flex flex-col  gap-6">
            <Input @onInput="onInputUsername" :value="username" :placeholder="'Digite seu e-mail'" :type="'text'" icon="person" />
            <Input @onInput="onInputPassword" :value="password" placeholder="Digite sua senha" type="password" icon="key"/>
            <button 
            @click.prevent="executeLogin"
            class="
                w-full
                h-10
                bg-blue-500
                rounded
                text-white text-l
                font-bold
                hover:bg-blue-700 hover:text-white
              ">
              Entrar
            </button>
            <div v-if="error">
              <span class="text-red-300">{{error}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "../components/Input.vue";
import useLogin from "@/helpers/useLogin";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  components: { Input },
  setup() {
    const router = useRouter();
    const { error, login } = useLogin()
    const username = ref("");
    const password = ref("");
    const onInputUsername = (value) => {
      username.value = value;
    };
    const onInputPassword = (value) => {
      password.value = value
    };
    const executeLogin = () => {
      login(username.value, password.value).then((r) => {
        router.push({name: "home"})
      })
    }
    return { username, password, executeLogin, onInputPassword, onInputUsername, error }
  }
};
</script>

<style>
body {
 overflow-x: hidden !important;
}

.box-login {
  background: linear-gradient(134.77deg,
      #232323 0%,
      #303030 37.88%,
      #232323 65.57%,
      #000000 100%);
  backdrop-filter: blur(8px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
  width: 30rem;
  height: 30rem;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .box-login {
    width: 100vw !important;
    height: 60vh !important;
  }
}
</style>