<template>
  <div class="p-6">
    <div class="bg-white rounded-md w-full p-6">
      <header>
        <slot name="header-content"></slot>
      </header>
      <body class="bg-white mt-4">
        <slot name="body-content"></slot>
      </body>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>