import api from "./axios";
import { ref } from "vue";
import store from "@/store";

const error = ref(null);
const loadRecentGroups = async () => {
  const r = await api.get("/api/v1/groups");
  if (r.status !== 200) error.value = "Falhou";
  return r.data;
};

const filterGroups = async (filters) => {
  const r = await api.get("/api/v1/groups", { params: filters });
  if (r.status !== 200) error.value = "Falhou";
  return r.data;
};

const getGroup = async (id) => {
  const r = await api.get(`/api/v1/groups/${id}`);
  if (r?.status !== 200) error.value = "Falhou";
  return r.data;
};

const loadTotal = async () => {
  return api
    .get("/api/v1/totals")
    .then((response) => response.data)
    .then((data) => {
      store.commit("setTotals", data);
      return data;
    });
};

const removePubli = async (group_id, publi_id) => {
  const data = {
    publi_id: publi_id,
  };
  return api
    .post(`/api/v1/groups/${group_id}/remove-publi`, data)
    .then((response) => response.data)
    .then((data) => {
      return data;
    });
};

const removeTablet = async (group_id, tablet_id) => {
  const data = {
    tablet_id: tablet_id,
  };
  return api
    .post(`/api/v1/groups/${group_id}/remove-tablet`, data)
    .then((response) => response.data)
    .then((data) => {
      return data;
    });
};
const useGroups = () => {
  return {
    error,
    loadRecentGroups,
    loadTotal,
    getGroup,
    filterGroups,
    removePubli,
    removeTablet,
  };
};
export default useGroups;
