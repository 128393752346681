<template>
  <div>
    <suspense>
      <template #fallback>Loading...</template>
      <router-view />
    </suspense>
  </div>
</template>
<style>
body {
  overflow-x: hidden;
  background: #e2e8f0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
#app {
  font-family: "Roboto", sans-serif;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
