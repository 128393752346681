import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView";
import store from "@/store/index";
import { defineAsyncComponent } from "vue";
// import GroupDetailsView from "@/views/GroupDetailsView";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/groups/:id",
    name: "group-details",
    component: () => import("@/views/GroupDetailsView.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (!Object.prototype.hasOwnProperty.call("username", store.getters.user))
    store.dispatch("getUserData");
  const publicPages = ["/login", "/register"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = store.getters.user;
  if (authRequired && Object.keys(authStore).length === 0) {
    return "/login";
  }
});
export default router;
