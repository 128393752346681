import { createApp } from "vue";
import MainHeader from "@/components/MainHeader.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/tailwind.css";
createApp(App)
  .use(store)
  .use(router)
  .component("v-select", vSelect)
  .component("main-header", MainHeader)
  .mount("#app");
