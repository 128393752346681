<template>
    <div class="px-3">
        <div class="w-full md:flex md:items-center md:w-auto " id="menu">
            <ul
            class="
                text-base text-white
                md:flex
                md:justify-between
                md:pt-0
                md:items-end
                "
            >
            <li>
                <a class="md:p-4 py-2 block hover:text-teal-600" href="#"
                >Clientes</a
                >
            </li>
            <li>
                <span @click.prevent="logout" class="md:p-4 py-2 font-extrabold block hover:text-purple-500" href="#"
                >Sair</span>
            </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
    name: "MenuItems",
    setup(_, { emit }) {
        const logout = () => emit("logout")
        return { logout }
    }
}
</script>