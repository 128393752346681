import { HttpStatusCode } from "axios";
import { createStore } from "vuex";

export default createStore({
  state: {
    isLogged: false,
    user: {},
    accesstoken: "",
    refreshtoken: "",
    totals: {},
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    totals: (state) => {
      if (Object.keys(state.totals).length === 0) {
        state.totals = JSON.parse(localStorage.getItem("totals")) ?? {};
        return state.totals;
      }
      return state.totals;
    },
    accessToken: async (state) => {
      return state.accesstoken;
    },
    refreshToken: async (state) => {
      return state.refreshtoken;
    },
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    setLoggedIn(state) {
      if (localStorage.getItem("user")) {
        state.isLogged = true;
      }
    },
    setLoggedOut(state) {
      state.isLogged = false;
      localStorage.clear();
    },
    setToken(state, token) {
      localStorage.setItem("token", JSON.stringify(token));
      state.accesstoken = token.access;
      state.refreshtoken = token.refresh;
    },
    setTotals(state, totals) {
      localStorage.setItem("totals", JSON.stringify(totals));
      state.totals = totals;
    },
  },
  actions: {
    getUserData({ commit, state }) {
      if (localStorage.getItem("user") && localStorage.getItem("token")) {
        commit("setUser", JSON.parse(localStorage.getItem("user")));
        commit("setToken", JSON.parse(localStorage.getItem("token")));
        commit("setLoggedIn");
      }
    },
    logout({ commit, state }) {
      commit("setLoggedOut");
    },
  },
  modules: {},
});
