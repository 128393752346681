import api from "./axios";
import { ref } from "vue";
import store from "@/store/index";

const error = ref(null);
const login = async (email, password) => {
  const resource = {
    username: email,
    password: password,
  };
  await api
    .post("/api/v1/api/token", resource)
    .then((response) => response.data)
    .then((data) => {
      store.commit("setUser", {
        email: data.email,
        username: data.username,
        name: data.name,
      });
      store.commit("setLoggedIn");
      store.commit("setToken", {
        access: data.access,
        refresh: data.refresh,
      });
      return true;
    })
    .catch((e) => {
      if ([401].includes(e.request.status)) {
        error.value = "Usuário não encontrado";
      } else if ([500, 503].includes(e.request.status)) {
        error.value = "Erro no servidor";
      }
    });
};

const useLogin = () => {
  return { error, login };
};

export default useLogin;
