<template>
  <div
    class="bg-background-login bg-cover bg-right-top bg-no-repeat md:bg-center flex md:flex-col w-screen h-22 md:h-72 lg:h-72"
  >
    <div class="text-white w-full">
      <div
        @click.prevent="toggleMenu"
        class="col-span-6 xs:hidden sm:block md:block lg:block cursor-pointer"
      >
        <div class="flex justify-end mr-6 mt-3 items-center">
          <div>
            <input
              type="text"
              placeholder="Buscar grupo"
              class="px-4 text-center text-sm border-opacity-50 bg-transparent border rounded-sm h-7 border-white w-full"
            />
          </div>
          <div class="ml-5 flex items-center">
            <div class="ml-5 items-center hidden md:flex lg:flex xl:flex">
              <img
                class="w-8 h-8 rounded-full"
                src="../assets/woman.png"
                alt="Avatar"
              />
              <span class="ml-1 text-sm font-extrabold">Usuário tal</span>
            </div>
            <span
              class="ml-5 block md:hidden lg:hidden xl:hidden text-3xl font-extrabold material-symbols-outlined"
            >
              menu
            </span>

            <span
              @click.prevent="executeLogout"
              class="ml-5 hidden md:block lg:block xl:block text-3xl font-extrabold material-symbols-outlined"
            >
              logout
            </span>
          </div>
        </div>
        <MenuItems @logout="executeLogout" v-if="openMenu" />
      </div>
      <div class="md:grid md:grid-cols-6 m-6 md:m-16 items-center">
        <div class="col-span-2">
          <router-link :to="{ name: 'home' }">
            <img src="../assets/logo.svg" class="w-80" alt="" />
          </router-link>
        </div>
        <div class="col-span-4">
          <div class="flex items-start">
            <HeadIconVue
              class="mr-2"
              icon="tablet_mac"
              label="Ativos"
              :value="totals.total_tablets"
            />
            <HeadIconVue
              class="mr-2"
              icon="face"
              label="Clientes"
              :value="totals.total_clients"
            />
            <HeadIconVue
              class="mr-2"
              icon="smart_display"
              label="Publicidades"
              :value="totals.total_publis"
            />
            <HeadIconVue
              class="mr-2"
              icon="groups"
              label="Grupos"
              :value="totals.total_groups"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, toRefs } from "vue";
import useLogout from "@/helpers/useLogout";
import { useRouter } from "vue-router";
import HeadIconVue from "./HeadIcon.vue";
import MenuItems from "./MenuItems.vue";
import { useStore } from "vuex";

export default {
  name: "MainHeader",
  components: { HeadIconVue, MenuItems, MenuItems },
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();
    const openMenu = ref(false);
    const { logout } = useLogout();
    const toggleMenu = () => (openMenu.value = !openMenu.value);
    const totals = ref({
      total_clients: 0,
      total_groups: 0,
      total_tablets: 0,
      total_publis: 0,
    });
    const executeLogout = async () => {
      await logout();
      router.push("/login");
    };
    const store = useStore();
    totals.value = store.getters.totals;
    return { openMenu, toggleMenu, executeLogout, totals };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
