<template>
  <div class="home">
    <main-header />
    <BackGroundBox>
      <template #header-content>
        <div class="md:flex justify-between">
          <div>
            <h1 class="text-2xl font-extrabold">Gerenciamento de Grupos</h1>
            <span class="text-xs font-semibold">Gerencie os grupos abaixo</span>
          </div>
          <div class="md:flex mt-3">
            <input
              placeholder="Nome do grupo"
              class="md:w-60 pl-2 lg:w-60 w-full h-9 rounded-md border border-slate-300"
              type="text"
              @keyup.enter.prevent="setFilterName"
            />
            <v-select
              class="md:w-72"
              :placeholder="selected_city"
              :options="citiesOptions"
              v-model="selected_city"
            />
            <v-select
              class="md:w-72"
              placeholder="Estado"
              :about="'as'"
              :options="statesOptions"
              v-model="selected_state"
            />
          </div>
        </div>
      </template>
      <template #body-content>
        <Table :tableData="groupsData"></Table>
      </template>
    </BackGroundBox>
  </div>
</template>

<script>
// @ is an alias to /src
import useGroups from "@/helpers/useGroups";
import Table from "@/components/home/Table.vue";
import BackGroundBox from "@/components/home/BackGroundBox.vue";
import UploadComponent from "@/components/firebase/UploadComponent.vue";
const { error, loadRecentGroups, loadTotal, filterGroups } = useGroups();
export default {
  name: "HomeView",
  components: {
    Table,
    BackGroundBox,
    UploadComponent,
  },
  data() {
    return {
      groupsData: {},
      totals: {},
      citiesOptions: [],
      statesOptions: [],
      selected_city: "Cidade",
      selected_state: "State",
      filter_name: null,
      filters: {
        city: null,
        state: null,
        name: null,
      },
    };
  },
  watch: {
    selected_city: {
      async handler(city) {
        this.filters.city = city;
        this.groupsData = await filterGroups(this.filters);
      },
      deep: true,
    },
    selected_state: {
      async handler(state) {
        this.filters.state = state;
        this.groupsData = await filterGroups(this.filters);
      },
      deep: true,
    },
  },
  computed: {
    uniqueCities() {
      const uniqueCities = this.groupsData.results.map((group) => group.city);
      let c = [];
      for (let x in uniqueCities) {
        if (!c.includes(uniqueCities[x])) c.push(uniqueCities[x]);
      }
      return c;
    },
    uniqueStates() {
      const uniqueStates = this.groupsData.results.map((group) => group.state);
      let c = [];
      for (let x in uniqueStates) {
        if (!c.includes(uniqueStates[x])) c.push(uniqueStates[x]);
      }
      return c;
    },
  },
  methods: {
    async setFilterName(val) {
      this.filters.name = val.target.value;
      this.groupsData = await filterGroups(this.filters);
    },
  },
  async created() {
    this.groupsData = await loadRecentGroups();
    await loadTotal();
    this.citiesOptions = this.uniqueCities;
    this.statesOptions = this.uniqueStates;
  },
};
</script>
