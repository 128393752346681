import api from "./axios";
import { ref } from "vue";
import store from "@/store/index";

const error = ref(null);
const logout = async () => {
  store.commit("setLoggedOut");
};

const useLogout = () => {
  return { error, logout };
};
export default useLogout;
